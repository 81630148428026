import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Container,
  Grid,
  Header,
  Icon,
  Loader,
  Segment,
  Message,
  Modal,
  Button,
  Table,
  Dimmer,
  List,
} from "semantic-ui-react";

const LeaderboardDetailPage = (props) => {
  const [partnerData, setPartnerData] = useState(null);
  const [leaderboard, setLeaderboard] = useState([]);
  const [summary, setSummary] = useState([]);
  const [improvement, setImprovement] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setPartnerData(props.data);
    if (props.type === "table") {
      loadData(props.data);
    }
  }, [props.data]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadData = (param) => {
    let parameters = {
      challenge_id: param.Record_KEY,
      la_code: param.LA_Code,
      type: param.Leaderboard_Type,
      time_start: param.Time_Start,
      time_end: param.Time_End,
    };

    if (
      param.Sections.includes("improvement") ||
      param.Leaderboard_Type.includes("Learning improvement")
    ) {
      parameters.improvement = 1;
    }
    if (param.Sections.includes("summary")) {
      let settings = JSON.parse(param.Settings);
      parameters.number_of_winner = settings.number_of_winner;
      parameters.minimum_requirement = settings.minimum_requirement;
    }
    setLoading(true);
    fetch(
      process.env.REACT_APP_BACKENDPORTAL_URL + "challenge/getLeaderboard",
      {
        method: "POST",
        body: JSON.stringify(parameters),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setLoading(false);
        if (res.status === 200 || res.status === 404) {
          setLeaderboard(res.data);
          setSummary(res.summary === undefined ? [] : res.summary);
          setImprovement(res.improvement === undefined ? [] : res.improvement);
        }
      })
      .catch((e) => {
        toast.error(
          "Something wrong fetching partner leaderboard data, please contact the developer!"
        );
      });
  };

  const generateLeaderboard = (is_mission, type) => {
    let components = null;
    let wordNumber = partnerData.Leaderboard_Type.includes("Star")
      ? "star(s)"
      : "";
    if (type === "group" || type === "alliance") {
      if (is_mission) {
        if (type === "alliance") {
          components = (
            <table
              className="ui basic celled structured table tableAlliance"
              data-testid="tableAllianceID"
            >
              <thead>
                <tr>
                  <th rowSpan="2" style={{ textAlign: "center" }}>
                    Rank
                  </th>
                  <th rowSpan="2">Alliance Name</th>
                  <th rowSpan="2">Leader</th>
                  <th rowSpan="2" style={{ textAlign: "center" }}>
                    Alliance Level
                  </th>
                  <th colSpan="3" className="center aligned">
                    Mission Completed
                  </th>
                </tr>
                <tr>
                  <th style={{ textAlign: "center" }}>Daily</th>
                  <th style={{ textAlign: "center" }}>Weekly</th>
                  <th style={{ textAlign: "center" }}>Monthly</th>
                </tr>
              </thead>
              <Table.Body>
                {leaderboard.map((v, k) => (
                  <Table.Row key={k}>
                    <Table.Cell textAlign="center">{k + 1}</Table.Cell>
                    <Table.Cell>{v.alliance_name}</Table.Cell>
                    <Table.Cell>
                      {v.leader_mayor_name} ({v.leader_city_name})
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {v.alliance_level}
                    </Table.Cell>
                    <Table.Cell textAlign="center">{v.daily}</Table.Cell>
                    <Table.Cell textAlign="center">{v.weekly}</Table.Cell>
                    <Table.Cell textAlign="center">{v.monthly}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </table>
          );
        } else {
          components = (
            <table
              className="ui celled structured table"
              data-testid="tableNonAllianceID"
            >
              <thead>
                <tr>
                  <th rowSpan="2">Rank</th>
                  <th rowSpan="2">Group Name</th>
                  <th rowSpan="2">Teacher</th>
                  <th colSpan="3" className="center aligned">
                    Mission Completed
                  </th>
                </tr>
                <tr>
                  <th>Daily</th>
                  <th>Weekly</th>
                  <th>Monthly</th>
                </tr>
              </thead>
              <Table.Body>
                {leaderboard.map((v, k) => (
                  <Table.Row key={k}>
                    <Table.Cell>{k + 1}</Table.Cell>
                    <Table.Cell>{v.group_name}</Table.Cell>
                    <Table.Cell>
                      {v.teacher_first_name} {v.teacher_last_name}
                    </Table.Cell>
                    <Table.Cell>{v.daily}</Table.Cell>
                    <Table.Cell>{v.weekly}</Table.Cell>
                    <Table.Cell>{v.monthly}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </table>
          );
        }
      } else {
        if (type === "group") {
          components = (
            <table
              className="ui very basic large table"
              data-testid="tableGroupID"
            >
              <Table.Body>
                {leaderboard.map((v, k) => (
                  <Table.Row key={k}>
                    <Table.Cell>{k + 1}</Table.Cell>
                    <Table.Cell>
                      <Modal
                        closeIcon
                        size="small"
                        trigger={
                          <Button className="linked">{v.group_name}</Button>
                        }
                      >
                        <Modal.Header>{v.group_name}</Modal.Header>
                        <Modal.Content>
                          {v.member_details && v.member_details.length > 0 ? (
                            <Table basic>
                              <thead>
                                <tr>
                                  <th>No.</th>
                                  <th>Mayor Name</th>
                                  <th>City Name</th>
                                </tr>
                              </thead>
                              <Table.Header>
                                {v.member_details.map((x, y) => (
                                  <Table.Row key={y}>
                                    <Table.Cell>{y + 1}</Table.Cell>
                                    <Table.Cell>{x.Mayor_Name}</Table.Cell>
                                    <Table.Cell>{x.City_Name}</Table.Cell>
                                  </Table.Row>
                                ))}
                              </Table.Header>
                            </Table>
                          ) : (
                            <Header as="h2">No Member Found...</Header>
                          )}
                        </Modal.Content>
                      </Modal>
                    </Table.Cell>
                    <Table.Cell>
                      {v.points} <sub>{wordNumber}</sub>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </table>
          );
        } else {
          components = (
            <table
              className="ui very basic large table"
              data-testid="tableNonGroupID"
            >
              <Table.Body>
                {leaderboard.map((v, k) => (
                  <Table.Row key={k}>
                    <Table.Cell>{k + 1}</Table.Cell>
                    <Table.Cell>
                      <Modal
                        closeIcon
                        size="small"
                        trigger={
                          <Button className="linked">{v.alliance_name}</Button>
                        }
                      >
                        <Modal.Header>{v.alliance_name}</Modal.Header>
                        <Modal.Content>
                          {v.member_details.length > 0 ? (
                            <Table basic>
                              <thead>
                                <tr>
                                  <th>No.</th>
                                  <th>Mayor Name</th>
                                  <th>City Name</th>
                                </tr>
                              </thead>
                              <Table.Header>
                                {v.member_details.map((x, y) => (
                                  <Table.Row key={y}>
                                    <Table.Cell>{y + 1}</Table.Cell>
                                    <Table.Cell>{x.Mayor_Name}</Table.Cell>
                                    <Table.Cell>{x.City_Name}</Table.Cell>
                                  </Table.Row>
                                ))}
                              </Table.Header>
                            </Table>
                          ) : (
                            <Header as="h2">No Member Found...</Header>
                          )}
                        </Modal.Content>
                      </Modal>
                    </Table.Cell>
                    <Table.Cell>
                      {v.points} <sub>{wordNumber}</sub>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </table>
          );
        }
      }
    } else {
      if (is_mission) {
        components = (
          <table
            className="ui celled structured table"
            data-testid="tableMissionID"
          >
            <thead>
              <tr>
                <th rowSpan="2">Rank</th>
                <th rowSpan="2">Name</th>
                <th colSpan="3" className="center aligned">
                  Mission Completed
                </th>
              </tr>
              <tr>
                <th>Daily</th>
                <th>Weekly</th>
                <th>Monthly</th>
              </tr>
            </thead>
            <Table.Body>
              {leaderboard.map((v, k) => (
                <Table.Row key={k}>
                  <Table.Cell>{k + 1}</Table.Cell>
                  <Table.Cell>
                    {v.mayor_name} ({v.city_name})
                  </Table.Cell>
                  <Table.Cell>{v.daily}</Table.Cell>
                  <Table.Cell>{v.weekly}</Table.Cell>
                  <Table.Cell>{v.monthly}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </table>
        );
      } else {
        components = (
          <table
            className="ui very basic large table"
            data-testid="tableIndividualID"
          >
            <Table.Body>
              {leaderboard.map((v, k) => (
                <Table.Row key={k}>
                  <Table.Cell>{k + 1}</Table.Cell>
                  <Table.Cell>
                    {v.mayor_name} ({v.city_name})
                  </Table.Cell>
                  <Table.Cell>
                    {v.points} <sub>{wordNumber}</sub>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </table>
        );
      }
    }
    return components;
  };

  const generateImprovementTable = () => {
    if (improvement.length === 0) {
      return (
        <Header as="h3" color="grey" data-testid="noDataFoundTextID">
          No data found...
        </Header>
      );
    }

    return (
      <Table
        basic
        className="tableImprovement"
        data-testid="tableImprovementID"
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>No.</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Diagnostic result</Table.HeaderCell>
            <Table.HeaderCell>Current level</Table.HeaderCell>
            <Table.HeaderCell>CEFR improvement?</Table.HeaderCell>
            <Table.HeaderCell>Number of levels improved</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {improvement.map((v, k) => (
            <Table.Row key={k}>
              <Table.Cell>{k + 1}</Table.Cell>
              <Table.Cell>
                {v.Chat_Name} ({v.User_Name})
              </Table.Cell>
              <Table.Cell>{v.diag_cefr}</Table.Cell>
              <Table.Cell>{v.current_cefr}</Table.Cell>
              <Table.Cell>
                {v.current_score - v.diag_score > 0 ? (
                  <Icon name="check" color="green" />
                ) : (
                  <Icon name="times" color="red" />
                )}
              </Table.Cell>
              <Table.Cell>{v.current_score - v.diag_score}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  };

  const generateSummary = () => {
    let wordNumber = partnerData.Leaderboard_Type.includes("Star")
      ? "star(s)"
      : "";

    if (summary.length === 0) {
      return (
        <Header as="h2" data-testid="noPlayerReachTextID">
          No player reached the minimum requirement yet!
        </Header>
      );
    }

    let components = null;
    let type = partnerData.Leaderboard_Type.split(",")[0];
    switch (type) {
      case "Learnalytics Group":
        components = (
          <table
            className="ui very basic large table"
            data-testid="tableLAGroupID"
          >
            <Table.Body>
              {summary.map((v, k) => (
                <Table.Row key={k}>
                  <Table.Cell>{k + 1}</Table.Cell>
                  <Table.Cell>{v.group_name}</Table.Cell>
                  <Table.Cell>
                    {v.points} <sub>{wordNumber}</sub>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </table>
        );
        break;
      case "Individual":
        components = (
          <table
            className="ui very basic large table"
            data-testid="tableLAIndividualID"
          >
            <Table.Body>
              {summary.map((v, k) => (
                <Table.Row key={k}>
                  <Table.Cell>{k + 1}</Table.Cell>
                  <Table.Cell>
                    {v.mayor_name} ({v.city_name})
                  </Table.Cell>
                  <Table.Cell>
                    {v.points} <sub>{wordNumber}</sub>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </table>
        );
        break;
      default:
        //alliance
        components = (
          <table
            className="ui very basic large table"
            data-testid="tableLAAllianceID"
          >
            <Table.Body>
              {summary.map((v, k) => (
                <Table.Row key={k}>
                  <Table.Cell>{k + 1}</Table.Cell>
                  <Table.Cell>{v.alliance_name}</Table.Cell>
                  <Table.Cell>
                    {v.points} <sub>{wordNumber}</sub>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </table>
        );
        break;
    }

    return components;
  };

  const generateContent = () => {
    let lType = partnerData.Leaderboard_Type.split(",");
    let leaderboardType = lType[0];
    let leaderboardSubType = lType[1];
    let sections = partnerData.Sections.split(",");
    let result = [];
    let isMission = leaderboardSubType.toLowerCase().includes("mission")
      ? true
      : false;
    let contentType =
      leaderboardType === "Learnalytics Group"
        ? "group"
        : leaderboardType === "Alliance"
        ? "alliance"
        : "individual";
    if (leaderboardType === "Learnalytics Group") {
    }

    if (leaderboard.length > 0) {
      sections.forEach((x) => {
        if (x === "leaderboard") {
          result.push(generateLeaderboard(isMission, contentType));
        }
      });
    } else {
      result.push(
        <Header as="h3" color="grey" data-testid="noDataFoundTextID">
          No Data Found...
        </Header>
      );
    }

    return result;
  };

  const generateRules = () => {
    let rules = partnerData.Rules.split(";");
    let comps = (
      <List data-testid="rulesListID">
        {rules.map((v, k) => (
          <List.Item key={k}>{v}</List.Item>
        ))}
      </List>
    );

    return comps;
  };

  return (
    <div className="partnerContainer" id="partnerContainer">
      {partnerData === null ? (
        <Loader active data-testid="loaderID" />
      ) : (
        <Container>
          {props.type === "table" ? (
            <>
              {partnerData.Sections.includes("summary") ? (
                <Segment basic className="leaderboardSegmentSearch">
                  <Header
                    as="h1"
                    className="leaderboardTitle"
                    textAlign="left"
                    data-testid="summaryTextID"
                  >
                    Summary
                  </Header>
                  {loading ? (
                    <Dimmer active inverted>
                      <Loader
                        active
                        size="large"
                        content="Fetching Data..."
                        data-testid="summaryLoaderID"
                      />
                    </Dimmer>
                  ) : (
                    generateSummary()
                  )}
                </Segment>
              ) : null}
              {partnerData.Sections.includes("leaderboard") ? (
                <Segment basic className="leaderboardSegmentSearch">
                  <Header
                    as="h1"
                    className="leaderboardTitle"
                    textAlign="left"
                    data-testid="allLeaderboarTextdID"
                  >
                    All Leaderboard
                  </Header>
                  {loading ? (
                    <Dimmer active inverted>
                      <Loader
                        active
                        size="large"
                        content="Fetching Data..."
                        data-testid="allLeaderboardLoaderID"
                      />
                    </Dimmer>
                  ) : (
                    generateContent().map((v, k) => {
                      return <div key={k}>{v}</div>;
                    })
                  )}
                </Segment>
              ) : null}

              {partnerData.Sections.includes("improvement") ? (
                <Segment basic className="leaderboardSegmentSearch">
                  <Header
                    as="h1"
                    className="leaderboardTitle"
                    textAlign="left"
                    data-testid="tableImprovementTextID"
                  >
                    Table Improvement
                  </Header>
                  {loading ? (
                    <Dimmer active inverted>
                      <Loader
                        active
                        size="large"
                        content="Fetching Data..."
                        data-testid="tableImprovementLoaderID"
                      />
                    </Dimmer>
                  ) : (
                    generateImprovementTable()
                  )}
                </Segment>
              ) : null}
            </>
          ) : (
            <>
              <Grid columns={"equal"} className="detailWrapperLeaderboard">
                <Grid.Column mobile={16} tablet={16} computer={12}>
                  <Header as="h1" data-testid="challengeNameTextID">
                    <Icon name="trophy" style={{ color: "#FDC500" }} />{" "}
                    {partnerData.Title}
                  </Header>
                  <Message info data-testid="challengeInfoTextID">
                    {partnerData.Information_Text}
                  </Message>
                  <Modal
                    size="small"
                    trigger={
                      <Button primary size="large" data-testid="ruleButtonID">
                        Rules!
                      </Button>
                    }
                    closeIcon
                  >
                    <Modal.Header data-testid="ruleModalTitleID">
                      Rules!
                    </Modal.Header>
                    <Modal.Content scrolling>
                      <Message info data-testid="rulesContentID">
                        {generateRules()}
                      </Message>
                    </Modal.Content>
                  </Modal>
                </Grid.Column>
              </Grid>
            </>
          )}
        </Container>
      )}
    </div>
  );
};

export default LeaderboardDetailPage;
