import { ToastContainer } from "react-toastify";
import BannerSection from "./Banner";
import FooterComponent from "./Footer";
import LeaderboardSection from "./Leaderboard";
import GoToTop from "./goTop";

export default function LandingPage() {
  return (
    <>
      <section id="banner">
        <BannerSection />
      </section>
      <section id="leaderboard">
        <LeaderboardSection />
      </section>
      <section id="footer">
        <FooterComponent />
      </section>
      <GoToTop />
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick={false}
        newestOnTop
        theme="colored"
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
    </>
  );
}
