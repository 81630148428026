import React, { useState, useEffect } from "react";
import { Button, Icon } from "semantic-ui-react";

// Import the necessary libraries

// Define the GoToTop component
const GoToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Function to handle scroll event
  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    const scrollDistance = 200; // Change this value to adjust when the component appears

    setIsVisible(scrollTop > scrollDistance);
  };

  // Add scroll event listener on component mount
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Function to handle click event
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Render the component
  return (
    <Button
      icon
      className={`goToTop ${isVisible ? "visible" : ""}`}
      onClick={handleClick}
      size="large"
      data-testid="gotoTopButtonID"
      secondary
    >
      <Icon name="arrow up" />
    </Button>
  );
};

export default GoToTop;
