import {
  Button,
  Container,
  Grid,
  GridColumn,
  Image,
  Menu,
  MenuItem,
} from "semantic-ui-react";
import SeLogo from "./assets/images/se.svg";
import edImage from "./assets/images/ed.svg";
import { handleScrollToElementByID } from "./utils";

export default function BannerSection() {
  return (
    <div className="bannerWrapper">
      <Menu borderless inverted className="navbarMenu">
        <Container>
          <MenuItem header>
            <Image src={SeLogo} data-testid="seLogoID" />
          </MenuItem>
        </Container>
      </Menu>
      <Container className="contentBannerWrapper">
        <Grid container columns={2}>
          <GridColumn mobile={16} tablet={16} computer={10}>
            <h1 data-testid="bannerTitleID">
              COME PLAY WITH YOUR <span>NEW FRIEND!</span>
            </h1>
            <p data-testid="bannerDescriptionID">
              As we bid farewell to our beloved game app, Dawn of Civilization,
              and sunset its journey, the challenges are now gone. But fret not!
              Embrace the dawn of a new era with our latest endeavor, where our
              new friend Ed, eagerly awaits to embark on a fresh learning
              adventure with you.
            </p>
            <div className="btnWrapperBanner">
              <Button
                size="massive"
                className="playBtn"
                data-testid="playButtonID"
                as={"a"}
                href="https://edbot.ai/"
                target="_blank"
              >
                PLAY
              </Button>
              <Button
                size="massive"
                basic
                className="leaderboardBtn"
                data-testid="leaderboardButtonID"
                onClick={() => handleScrollToElementByID("leaderboard")}
              >
                View leaderboard
              </Button>
            </div>
          </GridColumn>
          <GridColumn mobile={16} tablet={16} computer={6}>
            <Image
              src={edImage}
              style={{ marginTop: -100 }}
              className="edImage"
              centered
              data-testid="edImageID"
            />
          </GridColumn>
          <GridColumn only="mobile tablet" width={16}>
            <div className="mBtnWrapper">
              <Button
                size="massive"
                className="playBtn"
                fluid
                data-testid="playButtonID"
                as={"a"}
                href="https://edbot.ai/"
                target="_blank"
              >
                PLAY
              </Button>
              <Button
                size="massive"
                basic
                className="leaderboardBtn"
                fluid
                data-testid="leaderboardButtonID"
                onClick={() => handleScrollToElementByID("leaderboard")}
              >
                View leaderboard
              </Button>
            </div>
          </GridColumn>
        </Grid>
      </Container>
    </div>
  );
}
