import React from "react";
import {
  Container,
  Grid,
  Header,
  Icon,
  List,
  Segment,
} from "semantic-ui-react";

const FooterComponent = () => {
  return (
    <Segment id="c_footer" basic className="footerWrapper">
      <Container>
        <Grid columns="equal">
          <Grid.Column mobile={16} tablet={16} computer={3}>
            <Header inverted as="h4" data-testid="aboutTextID">
              About
            </Header>
            <List>
              <List.Item
                as="a"
                href="mailto:info@solveeducation.org"
                data-testid="aboutContactID"
                target="_blank"
              >
                Contact
              </List.Item>
              <List.Item
                as="a"
                href="https://solveeducation.org/privacy-policy/"
                data-testid="aboutPrivacyID"
                target="_blank"
              >
                Privacy Policy
              </List.Item>
              <List.Item
                as="a"
                href="https://solveeducation.org/terms-of-service/"
                data-testid="aboutTermID"
                target="_blank"
              >
                Term of Service
              </List.Item>
              <List.Item
                as="a"
                href="https://solveeducation.org/faqs/"
                data-testid="aboutFAQID"
                target="_blank"
              >
                FAQ
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={3}>
            <Header inverted as="h4" data-testid="servicesTextID">
              Services
            </Header>
            <List>
              <List.Item
                as="a"
                href="https://solveeducation.org/"
                data-testid="serviceSEID"
                target="_blank"
              >
                Solve Education!
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={3}>
            <Header inverted as="h4" data-testid="contactTextID">
              Contact
            </Header>
            <List className="contactWrapper">
              <List.Item
                as="a"
                href="tel:+622271520541"
                target="_blank"
                data-testid="contactPhoneID"
              >
                <div>
                  <Icon name="phone square" />
                </div>{" "}
                (+6222) 71520541
              </List.Item>
              <List.Item
                as="a"
                href="mailto:info@solveeducation.org"
                target="_blank"
                data-testid="contactEmailID"
              >
                <div>
                  <Icon name="envelope" />
                </div>{" "}
                info@solveeducation.org
              </List.Item>
              <List.Item
                as="a"
                target="_blank"
                href="https://maps.app.goo.gl/QraQrWEYiC1CaiSg7"
                data-testid="contactAddressID"
              >
                <div>
                  <Icon name="map marker alternate" />
                </div>
                Surapati Core, Jl. Phh. Mustofa No.155 Blok F3, Pasirlayung,
                Cibeunying Kidul, Bandung City, West Java 40192
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={1} />
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={6}
            textAlign="right"
            className="socialIconWrapper"
          >
            <a
              href="https://www.facebook.com/solveeducation/"
              target="_blank"
              rel="noreferrer"
              data-testid="facebookLinkID"
            >
              <Icon name="facebook" size="large" />
            </a>
            <a
              href="https://www.instagram.com/solveeducation/"
              target="_blank"
              rel="noreferrer"
              data-testid="instagramLinkID"
            >
              <Icon name="instagram" size="large" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCXFFHqO7BzyiaG1qt8MBhlg"
              target="_blank"
              rel="noreferrer"
              data-testid="youtubeLinkID"
            >
              <Icon name="youtube" size="large" />
            </a>
            <a
              href="https://www.linkedin.com/company/solve-education-/"
              target="_blank"
              rel="noreferrer"
              data-testid="linkedinLinkID"
            >
              <Icon name="linkedin" size="large" />
            </a>
          </Grid.Column>
        </Grid>
      </Container>
    </Segment>
  );
};

export default FooterComponent;
