import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Container,
  Dimmer,
  Divider,
  Form,
  Header,
  Icon,
  Image,
  Loader,
  Search,
  Segment,
} from "semantic-ui-react";
import LeaderboardDetailPage from "./LeaderboardDetail";
import { handleScrollToElementByID } from "./utils";

export default function LeaderboardSection() {
  const [partners, setPartners] = useState([]);
  const [parterList, setPartnerList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resultSearch, setResultSearch] = useState([]);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectValue, setSelectValue] = useState("");
  const [listSearchResult, setListSearchResult] = useState([]);
  const [activeChallenge, setActiveChallenge] = useState(null);
  const [isEmptyResult, setIsEmptyResult] = useState(false);

  useEffect(() => {
    loadPartners();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadPartners = () => {
    fetch(process.env.REACT_APP_CRM_URL + "api/manage_challenge/get_live")
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        let data = res.data;
        let tempPartner = [];
        let partners = [];
        data.forEach((x) => {
          tempPartner.push({
            key: x.Record_KEY,
            text: x.Title,
            value: x.LA_Code,
          });

          partners.push(x);
        });

        setPartners(data);
        setPartnerList(tempPartner);
        setLoading(false);
      })
      .catch((e) => {
        toast.error(
          "Something wrong on fetching partners, please contact developer."
        );
      });
  };

  const resultRenderer = ({ title }) => {
    let replace = searchValue.toLocaleLowerCase();
    let re = new RegExp(replace, "g");
    let splitted = title.toLowerCase().replace(re, "<b>" + replace + "</b>");
    return <p key={title} dangerouslySetInnerHTML={{ __html: splitted }} />;
  };

  const handleSearchChange = (e, data) => {
    setIsEmptyResult(false);
    setLoadingSearch(true);
    setSearchValue(data.value);
    let temp = partners.filter((x) =>
      x.Title.toLowerCase().includes(data.value.toLowerCase())
    );
    let result = [];
    temp.forEach((x) => {
      result.push({
        key: x.Record_KEY,
        id: x.LA_Code,
        title: x.Title,
      });
    });
    setResultSearch(result);
    setLoadingSearch(false);
  };

  const searchClickHandle = () => {
    if (searchValue === "") {
      return false;
    }
    setActiveChallenge(null);
    setSelectValue("");
    let data = partners.filter((x) =>
      x.Title.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
    );
    setListSearchResult(data);
    if (data.length === 0) {
      setIsEmptyResult(true);
    } else {
      setIsEmptyResult(false);
    }
  };

  const onChangeDropdown = (e, data) => {
    if (e._reactName === "onClick") {
      setChallenge(data.value);
      setSearchValue("");
    }
  };

  const goToChallengeTable = () => {
    setTimeout(() => {
      handleScrollToElementByID("partnerContainer");
    }, 100);
  };

  const setChallenge = (code, e) => {
    setSelectValue(code);
    let result = partners.filter((x) => x.LA_Code === code);
    setActiveChallenge(result[0]);
    goToChallengeTable();
  };

  return (
    <div className="leaderboardWrapper">
      <Container>
        <Header
          as="h1"
          className="leaderboardTitle"
          data-testid="leaderboardTitleID"
        >
          Leaderboard
        </Header>
        <Dimmer.Dimmable
          as={Segment}
          dimmed={loading}
          className="leaderboardSegmentSearch"
          basic
        >
          <Form size="big" className="partnerForm">
            <Form.Group>
              <Form.Dropdown
                onChange={onChangeDropdown}
                value={selectValue}
                placeholder="Select the challenge"
                fluid
                selection
                options={parterList}
                width={12}
                className="leaderboardDropdown"
                data-testid="leaderboardDropdownID"
              />
              <Form.Field width={6}>
                <Search
                  icon={false}
                  input={{
                    action: { icon: "search", onClick: searchClickHandle },
                  }}
                  placeholder="Or search the challenge"
                  loading={loadingSearch}
                  results={resultSearch}
                  resultRenderer={resultRenderer}
                  value={searchValue}
                  onResultSelect={(e, data) => {
                    setSearchValue(data.result.title);
                    setChallenge(data.result.id);
                  }}
                  onSearchChange={handleSearchChange}
                  className="searchInput"
                  data-testid="searchInputID"
                />
              </Form.Field>
            </Form.Group>
          </Form>
          <Divider hidden />
          {activeChallenge === null ? (
            <Dimmer.Dimmable as={Container} dimmed>
              {listSearchResult.length > 0 ? (
                <div>
                  <i className="textResult" data-testid="textResultID">
                    This is your result for “<b>{searchValue}</b>” click or tap
                    to see the detail
                  </i>
                  {listSearchResult.map((v, k) => (
                    <Segment
                      as={"button"}
                      key={k}
                      className="resultSegment"
                      basic
                      onClick={setChallenge.bind(null, v.LA_Code)}
                      style={{ width: "100%" }}
                    >
                      <Header as="h2">
                        <Icon
                          name="trophy"
                          style={{ color: "#FDC500" }}
                          size="tiny"
                          data-testid="partnerNameID"
                        />{" "}
                        {v.Title}
                      </Header>
                    </Segment>
                  ))}
                </div>
              ) : isEmptyResult ? (
                <>
                  <Header
                    as="h1"
                    textAlign="center"
                    color="grey"
                    data-testid="noFoundID"
                  >
                    Sorry, we can’t find the challenge for “{searchValue}”
                  </Header>
                  <Image
                    centered
                    src={require("./assets/images/empty_partner.svg").default}
                    size="large"
                    data-testid="emptyImageID"
                  />
                </>
              ) : (
                <>
                  <Header
                    as="h1"
                    textAlign="center"
                    color="grey"
                    data-testid="selectTextChallengeID"
                  >
                    Please select the challenge
                  </Header>
                  <Image
                    centered
                    src={require("./assets/images/partner_svg.svg").default}
                    size="large"
                    data-testid="imageSelectTextID"
                  />
                </>
              )}
            </Dimmer.Dimmable>
          ) : null}

          <Dimmer active={loading} inverted data-testid="loadingID">
            <Loader active>Fetching Challenge Data...</Loader>
          </Dimmer>

          {activeChallenge !== null ? (
            <LeaderboardDetailPage data={activeChallenge} type="detail" />
          ) : null}
        </Dimmer.Dimmable>
      </Container>
      <Divider hidden />
      {activeChallenge !== null ? (
        <LeaderboardDetailPage data={activeChallenge} type="table" />
      ) : null}
    </div>
  );
}
